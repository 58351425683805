<template>
  <Modal type="regular" :title="modalTitle" @close="$emit('close')">
    <component
      :is="linkTypeComponent"
      v-if="linkTypeComponent"
      :linking-data="linkingData"
      :has-game-account="hasGameAccount"
      @bindGame="bindGame"
      @successBind="$emit('successBind')"
      @redirectToLesta="redirectToLesta"
    />
  </Modal>
</template>

<script>
import Modal from '@src/components/v2/Modal.vue';

import SteamModalContent from '@src/features/linkSteamGame/ui/SteamModalContent.vue';
import BattlenetModalContent from '@src/features/linkBattlenetGame/ui/BattlenetModalContent.vue';
import LestaModalContent from '@src/features/linkLestaGame/ui/LestaModalContent.vue';
import LestaOrVkplayModalContent from '@src/features/linkLestaOrVkplayGame/ui/LestaOrVkplayModalContent.vue';
import PubgModalContent from '@src/features/linkPubgGame/ui/PubgModalContent.vue';
import SimpleLinkModalContent from '@src/features/linkSimpleGame/ui/SimpleLinkModalContent.vue';

export default {
  name: 'LinkGameModal',
  components: {
    Modal,
    LestaModalContent,
    SteamModalContent,
    BattlenetModalContent,
    LestaOrVkplayModalContent,
    PubgModalContent,
    SimpleLinkModalContent,
  },
  props: {
    linkingData: {
      type: Object,
      required: true,
    },
    hasGameAccount: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('application', ['getGame']),

    game() {
      return this.getGame(this.linkingData?.gameId);
    },

    linkTypeComponent() {
      return {
        simple: 'SimpleLinkModalContent',
        steam: 'SteamModalContent',
        pubg: 'PubgModalContent',
        battlenet: 'BattlenetModalContent',
        lesta: 'LestaModalContent',
        lestaOrLink: 'LestaOrVkplayModalContent',
      }[this.game.linkType];
    },

    modalTitle() {
      let modalTitle = '';
      if (this.linkingData.isLinking) {
        modalTitle = `${this.$t('profile.binding_gameAccount')} ${
          this.game?.name
        }`;
      }
      if (this.linkingData.isUnlinking) {
        modalTitle = `${this.$t('profile.unbinding_gameAccount')} ${
          this.game?.name
        }`;
      }
      return modalTitle;
    },
  },

  methods: {
    bindGame(userNickname) {
      this.$emit('bindGame', userNickname);
    },
    redirectToLesta(gameId) {
      this.$emit('redirectToLesta', gameId);
    },
  },
};
</script>

<style lang="scss" scoped>
.error-links {
  margin-top: 12px;
  text-align: left;
}
.error-links-list {
  margin-top: 6px;
}
</style>
