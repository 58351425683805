<template>
  <main-layout>
    <div class="pvp-container">
      <h1>Тест привязки игры</h1>
      <TestGamesListTable
        @error="setError"
        @startLinking="startLinking"
        @unlinkGame="unlinkGame"
      />
      <LinkGameModal
        v-if="isShowModal"
        :linking-data="linkingData"
        :has-game-account="hasGameAccount"
        @bindGame="bindGame"
        @successBind="successBind"
        @redirectToLesta="redirectToLesta"
        @close="closeModal"
      />
    </div>
  </main-layout>
</template>

<script>
import TestGamesListTable from '@components/ProfileComponents/ProfileEdit/TestGamesListTable.vue';
import LinkGameModal from '@src/widgets/linkGameModal';

export default {
  name: 'TestGameLink',

  components: {
    LinkGameModal,
    TestGamesListTable,
  },

  data() {
    return {
      isShowModal: false,
      isStartLinking: false,
      linkingData: {
        gameId: null,
        isLinking: false,
        isUnlinking: false,
        isLoading: false,
        error: null,
        errorData: null,
      },
      success: false,
    };
  },

  computed: {
    ...mapGetters('profile', [
      'hasMailAccount',
      'getLinkedGameAccount',
    ]),

    gameAccount() {
      return this.getLinkedGameAccount(this.linkingData?.gameId);
    },

    hasGameAccount() {
      if (this.gameAccount?.hasAccount && !this.success) {
        return false;
      }
      return this.gameAccount?.hasAccount;
    },
  },

  async mounted() {
    const gameId = Number(this.$route.query?.game);
    const isSteam = Boolean(this.$route.query?.['openid.identity']);
    const isBattlenet = Boolean(this.$route.query?.region);
    const lestaGameIds = [88, 94, 95, 96]; // 95 только на стенде, для прода удалить
    const isLesta = lestaGameIds.includes(gameId);

    if (isSteam) {
      await this.gameCallback(this.fetchSteamCallback, gameId);
    }

    if (isBattlenet) {
      await this.gameCallback(this.fetchBattlenetCallback, gameId);
    }

    if (isLesta) {
      await this.gameCallback(this.fetchLestaCallback, gameId);
    }

    const { secret } = this.$route.query;
    if (secret !== '0YHQtdC60YDQtdGC') {
      this.$router.push({ name: 'not-found' });
    }
  },

  methods: {
    ...mapActions('profile', ['fetchProfileGames']),

    setError(error) {
      this.linkingData.isLoading = false;
      this.linkingData.error = error;
    },

    async gameCallback(callback, gameId) {
      this.openModal();
      this.isStartLinking = true;
      this.linkingData = {
        gameId: gameId,
        isLinking: true,
        isLoading: true,
        isUnlinking: false,
      };
      await callback();
      await this.reloadGamesList();
      this.$router.replace({
        name: 'test-gamelink',
        query: { secret: '0YHQtdC60YDQtdGC' },
      });
    },

    fetchSteamCallback() {
      return api
        .get('/profile/game/steamcallback', {
          params: this.$route.query,
        })
        .catch(({ error, errorData }) => {
          this.linkingData.error = error;
          this.linkingData.errorData = errorData;
        })
        .finally(() => {
          this.linkingData.isLoading = false;
        });
    },

    fetchBattlenetCallback() {
      const { region, game, extra, code } = this.$route.query;
      return api
        .post('/profile/game/battlenetcallback', {
          game,
          code,
          region,
          extra,
        })
        .catch(({ error, errorData }) => {
          this.linkingData.error = error;
          this.linkingData.errorData = errorData;
        })
        .finally(() => {
          this.linkingData.isLoading = false;
        });
    },

    fetchLestaCallback() {
      this.linkingData.isLoading = true;
      return api
        .get('/profile/game/lestacallback', {
          params: this.$route.query,
        })
        .catch(({ error, errorData }) => {
          this.linkingData.error = error;
          this.linkingData.errorData = errorData;
        })
        .finally(() => {
          this.linkingData.isLoading = false;
        });
    },
    unlinkGame(gameId) {
      this.linkingData = {
        gameId: gameId,
        isLinking: false,
        isLoading: true,
        isUnlinking: true,
      };
      return api
        .post('/profile/game/unlink', {
          game: Number(gameId),
        })
        .then((data) => {
          if (data.success) {
            this.success = data.success;
            this.$emit('unlinked');
          }
        })
        .catch(({ error }) => {
          this.linkingData.isUnlinking = true;
          this.openModal();

          if (error === 'game_profile_unlink_error') {
            this.linkingData.error = this.$t(
              'profile.binding_errors_team',
            );
          } else if (error.code === 403) {
            this.linkingData.error = this.$t(
              'profile.binding_errors_tournament',
            );
          } else {
            this.linkingData.error = error?.message || error;
          }
        })
        .finally(() => {
          this.linkingData.isLoading = false;
          this.reloadGamesList();
        });
    },
    reloadGamesList() {
      // ===========================
      // METRICS события привязки игры
      // const tmrParams = {
      //   category: 'pvp',
      //   action: 'game_connect',
      //   label: this.game.name,
      //   url: window.location.href,
      // };
      // pushEvents('pvp_game_connect', tmrParams);
      // ===========================

      return this.fetchProfileGames().finally(() => {
        this.success = true;

        if (this.hasGameAccount) {
          this.$emit('linked');
        }
      });
    },
    async bindGame(userNickname) {
      const url = 'profile/game/save';
      const params = {
        id: this.linkingData?.gameId,
        value: userNickname,
      };
      this.linkingData.error = false;
      try {
        this.linkingData.isLoading = true;
        await api.post(url, params);
      } catch (err) {
        this.linkingData.error = err.error;
      } finally {
        this.linkingData.isLoading = false;
        this.reloadGamesList();
      }
    },
    successBind() {
      this.reloadGamesList();
    },
    redirectToLesta(gameId) {
      this.linkingData.isLoading = true;
      return api
        .get('/profile/game/lesta', {
          params: {
            id: gameId,
            extra: this.$router.currentRoute.path,
            needTransform: false,
          },
        })
        .then((params) => {
          window.location = params.url;
        })
        .catch((error) => {
          this.linkingData.error = error;
        });
    },
    openModal() {
      this.isShowModal = true;
    },
    closeModal() {
      this.isShowModal = false;
      this.linkingData = {
        gameId: null,
        isLinking: false,
        isUnlinking: false,
        isLoading: false,
        error: null,
        errorData: null,
      };
    },
    startLinking(gameId) {
      this.isStartLinking = true;
      this.linkingData.gameId = gameId;
      this.linkingData.isLinking = true;
      this.linkingData.isUnlinking = false;
      this.linkingData.isLoading = true;
      this.openModal();
    },
  },
};
</script>
