<template>
  <div>
    <div v-if="linkingData.error">
      <InfoBox class="modal__error" variant="error">
        {{ linkingData.error }}
      </InfoBox>
      <div v-if="instructions.length" class="error-links">
        <div class="error-links-title">
          {{ $t('global.instructions') }}:
        </div>
        <ul class="error-links-list">
          <li v-for="(info, key) in instructions" :key="key">
            <a target="_blank" :href="info.url">{{ info.name }}</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="modal__buttons">
      <Button
        size="regular"
        type="secondary"
        long
        :text="$t('info.continue_with_vkplay')"
        @click="showEnterNicknameForm"
      />
      <div
        v-show="isVisbleEnterNickname"
        class="modal__nickname-form"
      >
        <Input
          v-model="userNickname"
          class="modal__input"
          :placeholder="$t('info.enter_your_nickname')"
          :error-text="validationErrorText"
          @input="validateNickname"
        />
        <Button
          :disabled="!isValidForm"
          size="regular"
          type="primary"
          long
          :text="$t('actions.save')"
          @click="bindGame"
        />
      </div>
      <Button
        size="regular"
        type="tertiary"
        long
        :text="$t('info.sign_in_lesta')"
        @click="redirectToLesta"
      >
        <template #slotBefore>
          <div class="modal__icon">
            <icon name="lesta_icon" />
          </div>
        </template>
      </Button>
    </div>
    <InfoBox
      v-if="
        hasGameAccount &&
        !linkingData?.error &&
        !linkingData.isLoading
      "
      variant="success"
      class="modal__success"
    >
      <i18n
        :path="`profile.binding_games_${
          gameAccount.isMultiAccount ? 'bindedMulti' : 'binded'
        }`"
      >
        <b slot="game">{{ game.name }}</b>
      </i18n>
    </InfoBox>
  </div>
</template>

<script>
import InfoBox from '@components/BaseComponents/InfoBox.vue';
import Button from '@components/v2/ui/Button.vue';
import Input from '@components/v2/ui/Input.vue';

export default {
  name: 'LestaOrVkplayModalContent',

  components: {
    InfoBox,
    Button,
    Input,
  },

  props: {
    linkingData: {
      type: Object,
      required: true,
    },
    hasGameAccount: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isValidForm: false,
      isVisbleEnterNickname: false,
      validationErrorText: '',
      userNickname: '',
      isVisbleSteamButton: false,
    };
  },

  computed: {
    ...mapGetters('profile', ['isLoggedIn', 'getLinkedGameAccount']),
    ...mapGetters('application', ['getGame']),

    game() {
      return this.getGame(this.linkingData?.gameId);
    },

    gameAccount() {
      return this.getLinkedGameAccount(this.linkingData?.gameId);
    },

    instructions() {
      if (!this.game?.instructions) {
        return null;
      }

      const instructions = {
        link: ['new_game'],
        steam: ['steam_public'],
        steamOrVkPlay: ['steam_public'],
      }[this.game.linkType];

      return this.game.instructions.filter(({ code }) =>
        instructions?.includes(code),
      );
    },
  },

  watch: {
    'linkingData.isLoading': function (newValue) {
      this.isLoading = newValue;
    },
  },

  methods: {
    bindGame() {
      this.$emit('bindGame', this.userNickname);
    },

    showEnterNicknameForm() {
      this.isVisbleEnterNickname = !this.isVisbleEnterNickname;
    },

    validateNickname(value) {
      const regExp = /^[\w]*$/;
      this.isValidForm = regExp.test(value);
      if (value.length < 3) {
        this.validationErrorText = this.$t('errors.min_length_3');
        this.isValidForm = false;
      } else if (value.length > 24) {
        this.validationErrorText = this.$t('errors.max_length_24');
        this.isValidForm = false;
      } else if (!this.isValidForm) {
        this.validationErrorText = this.$t(
          'errors.only_latin_numbers_underscore',
        );
      } else {
        this.validationErrorText = '';
      }
    },

    redirectToLesta() {
      this.$emit('redirectToLesta', this.linkingData?.gameId);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal__loader {
  margin-bottom: 16px;
}

.modal__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.modal-button__text {
  color: #979797;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.modal__nickname {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 16px;
}

.modal__info {
  color: #979797;
  font-size: 14px;
  font-weight: 400;
  margin-top: 16px;
}

.modal__loader {
  margin-bottom: 16px;
}

.modal__error,
.modal__success {
  margin-top: 16px;
  margin-bottom: 16px;
}

.modal__nickname-form {
  width: 100%;
}
.modal__input {
  margin-bottom: 16px;
}

.modal__icon {
  color: #197dec;
}

.error-links {
  margin-bottom: 12px;
}
</style>
