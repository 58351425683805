var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.hasGameAccount &&
      !_vm.linkingData?.error &&
      !_vm.linkingData.isLoading
    )?_c('InfoBox',{attrs:{"variant":"success"}},[_c('i18n',{attrs:{"path":`profile.binding_games_${
        _vm.gameAccount.isMultiAccount ? 'bindedMulti' : 'binded'
      }`}},[_c('b',{attrs:{"slot":"game"},slot:"game"},[_vm._v(_vm._s(_vm.game.name))])])],1):_c('BaseForm',{staticClass:"simple",attrs:{"validate":_vm.beforeSubmit},on:{"submit":_vm.bindGame}},[(!_vm.linkingData.isUnlinking)?[_c('div',{staticClass:"modal-subtitle"},[_vm._v(" "+_vm._s(_vm.modalSubtitle)+" ")]),_c('BaseInput',{attrs:{"status":_vm.status,"placeholder":_vm.inputPlaceholder,"maxlength":_vm.maxLength,"message":_vm.messageHint},on:{"input":function($event){_vm.status = ''}},model:{value:(_vm.nickname),callback:function ($$v) {_vm.nickname=$$v},expression:"nickname"}}),_c('pvp-btn',{attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('games.bind_single'))+" ")])]:_vm._e(),(_vm.linkingData.error)?_c('InfoBox',{staticClass:"error",attrs:{"variant":"error"}},[_vm._v(" "+_vm._s(_vm.linkingData.error)+" ")]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }