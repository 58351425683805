<template>
  <loader v-if="isLoading" :size="32" />
  <BaseForm
    v-else
    class="battlenet"
    :validate="beforeSubmit"
    @submit="fetchAccount"
  >
    <h3>{{ $t('players.region_select') }}</h3>

    <div class="battlenet-regions">
      <pvp-btn
        v-for="region in regions"
        :key="region.key"
        variant="secondary"
        :class="[
          'battlenet-region',
          { active: region.url === regionUrl },
        ]"
        @click="setRegionUrl(region.url)"
      >
        {{ region.key }}
      </pvp-btn>
    </div>

    <pvp-btn
      type="submit"
      :disabled="regions.length === 0"
      class="battlenet-submit"
    >
      {{ $t('games.bind_single') }}
    </pvp-btn>

    <InfoBox
      v-if="error || linkingData?.error"
      class="modal__error"
      variant="error"
    >
      {{ error || linkingData?.error }}
    </InfoBox>
  </BaseForm>
</template>

<script>
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'BattlenetModalContent',
  components: {
    BaseForm,
    InfoBox,
  },
  props: {
    linkingData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      regions: [],
      regionUrl: '',
      isLoading: false,
      error: null,
    };
  },
  computed: {
    ...mapGetters('profile', ['getLinkedGameAccount']),

    gameAccount() {
      return this.getLinkedGameAccount(this.linkingData?.gameId);
    },
  },
  created() {
    this.fetchRegions();
  },
  methods: {
    fetchRegions() {
      this.isLoading = true;
      api
        .get('/profile/game/battlenet', {
          params: {
            id: this.linkingData?.gameId,
            extra: this.$router.currentRoute.path,
          },
        })
        .then((result) => {
          if (result.regions) {
            this.regions = _.map(result.regions, (url, key) => ({
              url,
              key,
            }));
          } else {
            this.error = this.$t('profile.binding_errors_battlenet');
          }
        })
        .catch(({ error }) => {
          this.erros = error;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    beforeSubmit() {
      const isValid = this.regionUrl ? true : false;
      if (!isValid) {
        this.error = this.$t('profile.binding_errors_needRegion');
      }
      return isValid;
    },

    fetchAccount() {
      this.isLoading = true;
      window.location = this.regionUrl;
    },

    setRegionUrl(url) {
      this.error = null;
      this.regionUrl = url;
    },
  },
};
</script>

<style lang="scss" scoped>
.battlenet {
  text-align: center;

  &-regions {
    margin-top: 12px;
    @include min-tablet() {
      display: flex;
      justify-content: center;
    }
  }

  &-region {
    margin: 4px;
    font-size: 20px;
    font-weight: bold;

    @include min-tablet() {
      height: 70px;
      width: 70px;
    }
    @include max-tablet() {
      width: 100%;
      height: 50px;
    }

    &.active {
      &,
      &:focus {
        box-shadow: 0 0 0 2px $azure;
      }
    }
  }

  &-submit {
    margin-top: 12px;
  }
}

.modal__error {
  margin-top: 12px;
}
</style>
