<template>
  <InfoBox
    v-if="hasGameAccount && !linkingData?.error && success"
    variant="success"
    class="modal__success"
  >
    <i18n
      :path="`profile.binding_games_${
        gameAccount.isMultiAccount ? 'bindedMulti' : 'binded'
      }`"
    >
      <b slot="game">{{ game.name }}</b>
    </i18n>
  </InfoBox>

  <div v-else>
    <InfoBox
      v-if="linkingData?.error"
      class="modal__error"
      variant="error"
    >
      {{ linkingData?.error }}
    </InfoBox>

    <BaseForm
      v-else
      :validate="beforeSubmit"
      class="simple"
      @submit="linkAccount"
    >
      <h3 class="title">{{ $t('players.nickname_game_input') }}</h3>

      <BaseInput
        v-model="nickname"
        :status="status"
        :placeholder="$t('players.nickname_game_label')"
        :maxlength="game.constraints.maxLength"
        @input="updateNickname"
      >
        <!-- eslint-disable vue/no-v-html -->
        <div
          slot="after-input"
          :class="['message', status]"
          v-html="game.constraints.hint"
        ></div>
      </BaseInput>

      <pvp-btn type="submit" :is-loading="isLoading">
        {{ $t('games.bind_single') }}
      </pvp-btn>

      <InfoBox v-if="error" class="modal__error" variant="error">
        {{ error }}
      </InfoBox>
    </BaseForm>
  </div>
</template>

<script>
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'PubgModalContent',
  components: {
    BaseInput,
    BaseForm,
    InfoBox,
  },
  props: {
    linkingData: {
      type: Object,
      required: true,
    },
    hasGameAccount: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    nickname: '',
    status: '',
    success: false,
    error: null,
    isLoading: false,
  }),
  computed: {
    ...mapGetters('application', ['getGame']),
    ...mapGetters('profile', ['getLinkedGameAccount']),

    game() {
      return this.getGame(this.linkingData?.gameId);
    },
    gameAccount() {
      return this.getLinkedGameAccount(this.linkingData?.gameId);
    },
  },
  created() {
    this.nickname = this.gameAccount?.accountsList?.[0]?.login;
  },
  methods: {
    beforeSubmit() {
      const nickname = this.nickname?.trim() || '';
      if (
        nickname.length === 0 ||
        nickname.length < this.game.constraints.minLength ||
        nickname.length > this.game.constraints.maxLength
      ) {
        this.status = 'error';
        return false;
      }
      return true;
    },

    updateNickname() {
      this.status = '';
      this.$emit('error', '');
    },

    linkAccount() {
      this.isLoading = true;
      api
        .post('/profile/game/pubglink', {
          nickName: this.nickname,
        })
        .then(({ success }) => {
          if (success) {
            this.$emit('successBind');
            this.success = success;
          }
        })
        .catch(({ error }) => {
          this.error = error;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 12px;
}

.simple {
  text-align: center;

  .label {
    margin-top: 12px;
  }
}

.button {
  margin-top: 12px;
}

.modal__error {
  margin-top: 12px;
}
</style>
