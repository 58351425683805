<template>
  <div>
    <InfoBox
      v-if="
        hasGameAccount &&
        !linkingData?.error &&
        !linkingData.isLoading
      "
      variant="success"
    >
      <i18n
        :path="`profile.binding_games_${
          gameAccount.isMultiAccount ? 'bindedMulti' : 'binded'
        }`"
      >
        <b slot="game">{{ game.name }}</b>
      </i18n>
    </InfoBox>

    <BaseForm
      v-else
      :validate="beforeSubmit"
      class="simple"
      @submit="bindGame"
    >
      <template v-if="!linkingData.isUnlinking">
        <div class="modal-subtitle">
          {{ modalSubtitle }}
        </div>

        <BaseInput
          v-model="nickname"
          :status="status"
          :placeholder="inputPlaceholder"
          :maxlength="maxLength"
          :message="messageHint"
          @input="status = ''"
        />

        <pvp-btn type="submit">
          {{ $t('games.bind_single') }}
        </pvp-btn>
      </template>

      <InfoBox v-if="linkingData.error" variant="error" class="error">
        {{ linkingData.error }}
      </InfoBox>
    </BaseForm>
  </div>
</template>

<script>
import BaseInput from '@components/BaseComponents/Form/BaseInput.vue';
import BaseForm from '@components/BaseComponents/Form/BaseForm.vue';
import InfoBox from '@src/components/BaseComponents/InfoBox.vue';

export default {
  name: 'SimpleLinkModalContent',
  components: {
    BaseInput,
    BaseForm,
    InfoBox,
  },
  props: {
    linkingData: {
      type: Object,
      required: true,
    },
    hasGameAccount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nickname: '',
      status: '',
      error: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('application', ['getGame']),
    ...mapGetters('profile', ['getLinkedGameAccount']),

    game() {
      return this.getGame(this.linkingData.gameId);
    },
    gameAccount() {
      return this.getLinkedGameAccount(this.linkingData.gameId);
    },

    // ВНИМАНИЕ КОСТЫЛЬ!!!
    // Если игра Mobile Legends: Bang Bang то показать информацию о том что вводить следует ID

    isMLBBGame() {
      // для прода сменить ID === 72
      const mlbbGameid = 72;
      return Number(this.linkingData.gameId) === mlbbGameid;
    },

    modalSubtitle() {
      return this.isMLBBGame
        ? this.$t('players.enter_game_id')
        : this.$t('players.nickname_game_input');
    },

    inputPlaceholder() {
      return this.isMLBBGame
        ? this.$t('players.enter_id_label')
        : this.$t('players.nickname_game_label');
    },

    maxLength() {
      return this.isMLBBGame ? 10 : this.game.constraints.maxLength;
    },

    messageHint() {
      return this.isMLBBGame
        ? this.$t('players.mlbb_game_hint')
        : this.game.constraints.hint;
    },
  },
  created() {
    this.nickname = this.gameAccount?.accountsList?.[0]?.login;
  },
  methods: {
    // ВНИМАНИЕ КОСТЫЛЬ!!!
    // Если игра Mobile Legends: Bang Bang то показать информацию о том что вводить следует ID
    beforeSubmit() {
      const nickname = this.nickname?.trim() || '';
      const regexpNumber = /^\d+$/;
      if (
        nickname.length === 0 ||
        nickname.length < this.game.constraints.minLength ||
        nickname.length > this.game.constraints.maxLength
      ) {
        this.status = 'error';
        return false;
      }
      if (
        this.isMLBBGame &&
        (!regexpNumber.test(nickname) ||
          nickname.length === 0 ||
          nickname.length < 7)
      ) {
        this.status = 'error';
        return false;
      }
      return true;
    },
    bindGame() {
      this.$emit('bindGame', this.nickname);
    },
  },
};
</script>

<style lang="scss" scoped>
.simple {
  text-align: center;

  .label {
    margin-top: 12px;
  }
}

.modal-subtitle {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 12px;
}

.button {
  margin-top: 12px;
}
.error {
  margin-top: 12px;
}
</style>
