var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.linkingData.error)?_c('div',[_c('InfoBox',{staticClass:"modal__error",attrs:{"variant":"error"}},[_vm._v(" "+_vm._s(_vm.linkingData.error)+" ")]),(_vm.instructions.length)?_c('div',{staticClass:"error-links"},[_c('div',{staticClass:"error-links-title"},[_vm._v(" "+_vm._s(_vm.$t('global.instructions'))+": ")]),_c('ul',{staticClass:"error-links-list"},_vm._l((_vm.instructions),function(info,key){return _c('li',{key:key},[_c('a',{attrs:{"target":"_blank","href":info.url}},[_vm._v(_vm._s(info.name))])])}),0)]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"modal__buttons"},[_c('Button',{attrs:{"size":"regular","type":"secondary","long":"","text":_vm.$t('info.continue_with_vkplay')},on:{"click":_vm.showEnterNicknameForm}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVisbleEnterNickname),expression:"isVisbleEnterNickname"}],staticClass:"modal__nickname-form"},[_c('Input',{staticClass:"modal__input",attrs:{"placeholder":_vm.$t('info.enter_your_nickname'),"error-text":_vm.validationErrorText},on:{"input":_vm.validateNickname},model:{value:(_vm.userNickname),callback:function ($$v) {_vm.userNickname=$$v},expression:"userNickname"}}),_c('Button',{attrs:{"disabled":!_vm.isValidForm,"size":"regular","type":"primary","long":"","text":_vm.$t('actions.save')},on:{"click":_vm.bindGame}})],1),_c('Button',{attrs:{"size":"regular","type":"tertiary","long":"","text":_vm.$t('info.sign_in_lesta')},on:{"click":_vm.redirectToLesta},scopedSlots:_vm._u([{key:"slotBefore",fn:function(){return [_c('div',{staticClass:"modal__icon"},[_c('icon',{attrs:{"name":"lesta_icon"}})],1)]},proxy:true}])})],1),(
      _vm.hasGameAccount &&
      !_vm.linkingData?.error &&
      !_vm.linkingData.isLoading
    )?_c('InfoBox',{staticClass:"modal__success",attrs:{"variant":"success"}},[_c('i18n',{attrs:{"path":`profile.binding_games_${
        _vm.gameAccount.isMultiAccount ? 'bindedMulti' : 'binded'
      }`}},[_c('b',{attrs:{"slot":"game"},slot:"game"},[_vm._v(_vm._s(_vm.game.name))])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }