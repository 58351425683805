<template>
  <div>
    <component
      :is="linkTypeComponent"
      :game-id="gameId"
      @startLinking="startLinking"
      @error="emitError"
      @unlinkGame="$emit('unlinkGame', gameId)"
    />
  </div>
</template>

<script>
import LinkSimpleGame from '@src/features/linkSimpleGame';
import LinkSteamGame from '@src/features/linkSteamGame';
import LinkBattlenetGame from '@src/features/linkBattlenetGame';
import LinkLestaGame from '@src/features/linkLestaGame';
import LinkLestaOrVkplayGame from '@src/features/linkLestaOrVkplayGame';
import LinkPubgGame from '@src/features/linkPubgGame';

export default {
  name: 'GameAccountLinker',

  components: {
    LinkSimpleGame,
    LinkSteamGame,
    LinkLestaGame,
    LinkBattlenetGame,
    LinkLestaOrVkplayGame,
    LinkPubgGame,
  },

  props: {
    gameId: {
      type: [Number, String],
      required: true,
    },
  },

  computed: {
    ...mapGetters('application', ['getGame']),

    game() {
      return this.getGame(this.gameId);
    },

    linkTypeComponent() {
      return {
        simple: 'linkSimpleGame',
        steam: 'linkSteamGame',
        pubg: 'linkPubgGame',
        battlenet: 'linkBattlenetGame',
        lesta: 'linkLestaGame',
        lestaOrLink: 'linkLestaOrVkplayGame',
      }[this.game.linkType];
    },
  },

  methods: {
    startLinking() {
      this.$emit('startLinking', this.gameId);
    },
    emitError(error) {
      this.$emit('error', error);
    },
  },
};
</script>
