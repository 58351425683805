var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isLoading)?_c('loader',{attrs:{"size":32}}):_vm._e(),(
      _vm.hasGameAccount &&
      !_vm.linkingData.isLoading &&
      !_vm.linkingData?.error
    )?_c('InfoBox',{attrs:{"variant":"success"}},[_c('i18n',{attrs:{"path":`profile.binding_games_${
        _vm.gameAccount?.isMultiAccount ? 'bindedMulti' : 'binded'
      }`}},[_c('b',{attrs:{"slot":"game"},slot:"game"},[_vm._v(_vm._s(_vm.game.name))])])],1):_vm._e(),(_vm.linkingData.error)?_c('div',{staticClass:"error-section"},[(_vm.linkingData.errorData)?_c('InfoBox',{attrs:{"variant":"error"}},[_vm._v(_vm._s(_vm.linkingData.error)+" "),_c('router-link',{attrs:{"to":`/player/${_vm.linkingData?.errorData?.hash}`}},[_vm._v(" "+_vm._s(_vm.linkingData?.errorData?.name)+" ")])],1):_c('InfoBox',{attrs:{"variant":"error"}},[_vm._v(" "+_vm._s(_vm.linkingData.error)+" ")]),(_vm.instructions?.length)?_c('div',{staticClass:"error-links"},[_c('div',{staticClass:"error-links-title"},[_vm._v(" "+_vm._s(_vm.$t('global.instructions'))+": ")]),_c('ul',{staticClass:"error-links-list"},_vm._l((_vm.instructions),function(info,key){return _c('li',{key:key},[_c('a',{attrs:{"target":"_blank","href":info.url}},[_vm._v(_vm._s(info.name))])])}),0)]):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }