<template>
  <div
    v-if="games.length"
    class="pvp-table games-list-table adaptive"
  >
    <div class="table-header">
      <div class="table-row">
        <div class="table-column">{{ $t('games.single') }}</div>
        <div class="table-column nickname">
          {{ $t('players.nickname_game_label') }}
        </div>
        <div class="table-column controls"></div>
      </div>
    </div>
    <div class="table-body">
      <div v-for="game in games" :key="game.id" class="table-row">
        <div class="table-column">
          <game-tag :id="game.id" :full-name="!appIsMobile" />
        </div>
        <div class="table-column nickname">
          <template v-if="game.hasAccount">
            <div
              v-for="account in game.accountsList"
              :key="account.hash"
              class="game-nick"
            >
              <div class="login">
                {{ account.login }}
                <Icon
                  v-if="account.isDefault && game.isMultiAccount"
                  name="check-circle"
                  inline
                />
              </div>
              <div v-if="game.isMultiAccount" class="server">
                {{ $t('global.server') }} {{ account.shardName }}
              </div>
            </div>
          </template>
        </div>
        <div class="table-column controls">
          <GameAccountLinker
            :game-id="game.id"
            @startLinking="$emit('startLinking', game.id)"
            @error="emitError"
            @unlinkGame="unlinkGameEmitter"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import GameAccountLinker from '@src/widgets/gameAccountLinker';

export default {
  name: 'TestGamesListTable',
  components: { Icon, GameAccountLinker },

  data: () => ({
    deafultAccountHash: {},
  }),
  computed: {
    ...mapGetters('application', ['appIsMobile']),
    ...mapGetters('profile', ['getProfileLinkedGamesAccounts']),

    games() {
      return this.getProfileLinkedGamesAccounts;
    },
  },
  watch: {
    games: {
      handler(value) {
        value.forEach(({ id, accountsList }) => {
          const hash =
            accountsList?.find((item) => item.isDefault)?.hash || '';
          Vue.set(this.deafultAccountHash, id, hash);
        });
      },
      immediate: true,
    },
  },
  methods: {
    unlinkGameEmitter(gameId) {
      this.$emit('unlinkGame', gameId);
    },
    emitError(error) {
      this.$emit('error', error);
    },
  },
};
</script>

<style lang="scss" scoped>
.pvp-table.adaptive {
  @include max-laptop() {
    margin-left: -12px;
    margin-right: -12px;
  }
  @include max-tablet() {
    border-left: none;
    border-right: none;
    box-shadow: none;
  }
}

.pvp-table .table {
  &-header {
    @include max-laptop() {
      display: none;
    }
  }

  &-row {
    @include min-laptop() {
      display: grid;
      grid-template-columns: 2fr 2fr 1fr;
    }
  }

  &-body {
    @include max-laptop() {
      padding-left: 0;
      padding-right: 0;
    }
    @include max-tablet() {
      background-color: transparent;
    }
  }
}

.nickname {
  flex-wrap: wrap;
}

.game-nick {
  width: 100%;

  .login {
    display: flex;
    align-items: center;

    .icon {
      color: $dark-pastel-green;
      margin-left: 8px;
    }
  }

  & + & {
    margin-top: 8px;
  }

  &.current {
    color: $azure;
  }

  @include max-laptop() {
    color: rgba(white, 0.5);
  }
}

.server {
  font-size: 14px;
  color: rgba(white, 0.5);
}
</style>
