<template>
  <div v-if="hasMailAccount" class="btns-group">
    <Button
      tag="button"
      size="regular"
      type="primary"
      long
      :text="bindButtonText"
      @click="startLinking()"
    />
    <Button
      v-if="canRelink"
      size="regular"
      type="tertiary"
      long
      :text="$t('account.unbind')"
      @click="unlinkGame"
    />
  </div>
</template>

<script>
import Button from '@components/v2/ui/Button.vue';

export default {
  name: 'LinkSimpleGame',
  components: {
    Button,
  },
  props: {
    gameId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('profile', [
      'hasMailAccount',
      'getLinkedGameAccount',
    ]),
    gameAccount() {
      return this.getLinkedGameAccount(this.gameId);
    },
    hasGameAccount() {
      if (this.gameAccount?.hasAccount && !this.success) {
        return false;
      }
      return this.gameAccount?.hasAccount;
    },
    canRelink() {
      return this.gameAccount?.hasAccount;
    },
    bindButtonText() {
      return this.canRelink
        ? this.$t('profile.binding_games_change')
        : this.$t('account.bind');
    },
    isVisibleLinkButton() {
      return this.hasGameAccount || this.canRelink;
    },
  },
  methods: {
    startLinking() {
      this.$emit('startLinking', this.gameId);
    },
    unlinkGame() {
      this.$emit('unlinkGame', this.gameId);
    },
  },
};
</script>

<style lang="scss" scoped>
.btns-group {
  min-width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
</style>
