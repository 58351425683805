<template>
  <div>
    <loader v-if="isLoading" :size="32" />

    <InfoBox
      v-if="
        hasGameAccount &&
        !linkingData.isLoading &&
        !linkingData?.error
      "
      variant="success"
    >
      <i18n
        :path="`profile.binding_games_${
          gameAccount?.isMultiAccount ? 'bindedMulti' : 'binded'
        }`"
      >
        <b slot="game">{{ game.name }}</b>
      </i18n>
    </InfoBox>

    <div v-if="linkingData.error" class="error-section">
      <InfoBox v-if="linkingData.errorData" variant="error"
        >{{ linkingData.error }}
        <router-link :to="`/player/${linkingData?.errorData?.hash}`">
          {{ linkingData?.errorData?.name }}
        </router-link>
      </InfoBox>
      <InfoBox v-else variant="error">
        {{ linkingData.error }}
      </InfoBox>
      <div v-if="instructions?.length" class="error-links">
        <div class="error-links-title">
          {{ $t('global.instructions') }}:
        </div>
        <ul class="error-links-list">
          <li v-for="(info, key) in instructions" :key="key">
            <a target="_blank" :href="info.url">{{ info.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import InfoBox from '@components/BaseComponents/InfoBox.vue';

export default {
  name: 'SteamModalContent',

  components: {
    InfoBox,
  },

  props: {
    linkingData: {
      type: Object,
      required: true,
    },
    hasGameAccount: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoading: true,
    };
  },

  computed: {
    ...mapGetters('profile', ['isLoggedIn', 'getLinkedGameAccount']),
    ...mapGetters('application', ['getGame']),

    game() {
      return this.getGame(this.linkingData?.gameId);
    },

    gameAccount() {
      return this.getLinkedGameAccount(this.linkingData?.gameId);
    },

    instructions() {
      if (!this.game?.instructions) {
        return null;
      }

      const instructions = {
        link: ['new_game'],
        steam: ['steam_public'],
        steamOrVkPlay: ['steam_public'],
      }[this.game.linkType];

      return this.game.instructions.filter(({ code }) =>
        instructions?.includes(code),
      );
    },
  },

  watch: {
    'linkingData.isLoading': function (newValue) {
      this.isLoading = newValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.error-links {
  margin-top: 12px;
  margin-bottom: 12px;
}
</style>
